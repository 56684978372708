const API_URL = process.env.REACT_APP_API_URL;
export const login = async (user_email, user_password) => {
    const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_email, user_password })
    });
    return response.json();
}

export const register = async (user_name, user_email, user_password) => {
    const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name, user_email, user_password })
    });
    return response.json();
}

