import React from 'react';

const Home = () => {
  return (
    <div>
      <h1>Welcome to the Home Page</h1>
      <h2>If you are available to use skype. Please contact me. my skype name is "live:.cid.3d1ca0a85b3d846"</h2>
    </div>
  );
};

export default Home;
