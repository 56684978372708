import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  message,
  Space,
  Image,
} from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getUsers, updateUser, createUser, deleteUser } from './../../services/Base/Management'; // Ensure these API calls exist
import './UserManagement.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      width: '15%',
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
      width: '15%',
    },
    {
      title: 'Role',
      dataIndex: 'user_role',
      key: 'user_role',
      width: '10%',
    },
    {
      title: 'Country Code',
      dataIndex: 'country_code',
      key: 'country_code',
      width: '10%',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile_no',
      key: 'mobile_no',
      width: '10%',
    },
    {
      title: 'Profile',
      dataIndex: 'profile',
      key: 'profile',
      width: '20%',
    },
    {
      title: 'Profile Image',
      dataIndex: 'image_file',
      key: 'image_file',
      render: (text) =>
        text ? (
          <Image width={50} src={`http://localhost:5000/uploads/profiles/${text}`} alt="Profile" />
        ) : (
          'No Image'
        ),
      width: '10%',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      console.log(response);
      setUsers(response); // Ensure your API returns data in the correct structure
    } catch (error) {
      console.error('Failed to fetch users:', error);
      message.error('Failed to fetch users');
    }
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    form.setFieldsValue(user);
    if (user.image_file) {
      setFileList([
        {
          uid: '-1',
          name: 'Profile Image',
          status: 'done',
          url: `/uploads/${user.image_file}`,
        },
      ]);
    }
    setIsModalVisible(true);
  };

  const handleDelete = async (userId) => {
    try {
      // await deleteUser(userId); 
      message.success('User deleted successfully');
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Failed to delete user:', error);
      message.error('Failed to delete user');
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('user_name', values.user_name);
      formData.append('user_email', values.user_email);
      formData.append('user_role', values.user_role); // Add user_role to form submission
      formData.append('country_code', values.country_code);
      formData.append('mobile_no', values.mobile_no);
      formData.append('profile', values.profile);

      if (fileList.length > 0 && fileList[0]?.originFileObj) {
        formData.append('image_file', fileList[0].originFileObj);
      }

      if (editingUser) {
        // await updateUser(editingUser.id, formData); 
      } else {
        // await createUser(formData); 
      }

      setIsModalVisible(false);
      form.resetFields();
      setFileList([]);
      setEditingUser(null);
      message.success(`User ${editingUser ? 'updated' : 'created'} successfully`);
      fetchUsers();
    } catch (error) {
      console.error(`Failed to ${editingUser ? 'update' : 'create'} user:`, error);
      message.error(`Failed to ${editingUser ? 'update' : 'create'} user`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-management-container">
      <div className="user-management-header">
        <h1>
          <center>User Management</center>
        </h1>
        {/* <Button
          type="primary"
          onClick={() => {
            setEditingUser(null);
            form.resetFields();
            setFileList([]);
            setIsModalVisible(true);
          }}
          style={{ borderRadius: '4px' }}
        >
          Add New User
        </Button> */}
      </div>

      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
        locale={{ emptyText: 'No data' }}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
        }}
      />

      <Modal
        title={editingUser ? 'Edit User' : 'Add New User'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
          setEditingUser(null);
          setFileList([]);
        }}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="user_name"
            label="User Name"
            rules={[{ required: true, message: 'Please input user name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="user_email"
            label="Email"
            rules={[
              { required: true, message: 'Please input email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="user_role"
            label="Role"
            rules={[{ required: true, message: 'Please select a role!' }]}
          >
            <Select>
              <Select.Option value="user">User</Select.Option>
              <Select.Option value="staff">Staff</Select.Option>
              <Select.Option value="business">Business</Select.Option>
              <Select.Option value="trainer">Trainer</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="country_code"
            label="Country Code"
            rules={[{ required: true, message: 'Please select country code!' }]}
          >
            <Select>
              <Select.Option value="+1">+1 (USA)</Select.Option>
              <Select.Option value="+44">+44 (UK)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="mobile_no"
            label="Mobile Number"
            rules={[{ required: true, message: 'Please input mobile number!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="profile" label="Profile">
            <Input.TextArea />
          </Form.Item>

          <Form.Item name="image_file" label="Profile Image">
            <Upload
              listType="picture"
              fileList={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
              beforeUpload={() => false}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                {editingUser ? 'Update' : 'Create'}
              </Button>
              <Button
                onClick={() => {
                  setIsModalVisible(false);
                  form.resetFields();
                  setEditingUser(null);
                  setFileList([]);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagement;
