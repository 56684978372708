import React, { useState, useEffect } from "react";
import { fetchUsers, assignCredit } from "../../services/Base/AssignCredit";
import {
  Table,
  Select,
  InputNumber,
  Button,
  Typography,
  Space,
  Card,
  message,
} from "antd";
import "./AssignCredit.css";

const { Title } = Typography;
const { Option } = Select;

const CREDIT_TYPES = ["A", "B", "C", "D", "E"];

const AssignCredit = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [creditType, setCreditType] = useState("A");
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      setLoading(true);
      const data = await fetchUsers();
      console.log(data);
      const usersArray = Array.isArray(data) ? data : [data];

      const formattedData = usersArray.map((user, index) => ({
        key: index,
        id: user.id || user.user_id, // Use `id` or `user_id` based on server data
        name: user.user_name,
        email: user.user_email,
        role: user.user_role,
        country_code: user.country_code,
        mobile_no: user.mobile_no,
        remaining_credit: user.remaining_credits,
      }));
      setUsers(formattedData);
    } catch (error) {
      console.error("Failed to load users:", error);
      message.error("Failed to load users");
    } finally {
      setLoading(false);
    }
  };

  const handleAssignCredit = async () => {
    if (!selectedUser || !amount) {
      message.warning("Please select a user and enter an amount");
      return;
    }

    try {
      setLoading(true);
      console.log(selectedUser);
      await assignCredit(selectedUser.id, creditType, Number(amount));
      message.success("Credit assigned successfully");
      setAmount(null);
      setSelectedUser(null);
      await loadUsers();
    } catch (error) {
      console.error("Failed to assign credit:", error);
      message.error("Failed to assign credit");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Mobile No",
      dataIndex: "mobile_no",
      key: "mobile_no",
    },
    {
      title: "Country Code",
      dataIndex: "country_code",
      key: "country_code",
    }, 
    {
      title: "Remain Credits",
      dataIndex: "remaining_credit",
      key: "remaining_credit",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => setSelectedUser(record)}
          size="small"
        >
          Select
        </Button>
      ),
    },
  ];

  return (
    <div className="assign-credit-container">
      <Title level={2}>Assign Credits</Title>

      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        className="users-table"
      />

      <Card
        title="Assign Credit"
        className="assign-credit-form"
        style={{ marginTop: 24 }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {selectedUser && (
            <div className="selected-user">
              <b>Selected User:</b> {selectedUser.name} ({selectedUser.email})
              <br />
              <b>Role:</b> {selectedUser.role}
              <br />
              <b>Mobile:</b> {selectedUser.country_code} {selectedUser.mobile_no}
            </div>
          )}

          <Space>
            <Select
              value={creditType}
              onChange={setCreditType}
              style={{ width: 120 }}
            >
              {CREDIT_TYPES.map((type) => (
                <Option key={type} value={type}>
                  Type {type}
                </Option>
              ))}
            </Select>

            <InputNumber
              placeholder="Amount"
              value={amount}
              onChange={setAmount}
              style={{ width: 200 }}
              min={1}
            />
          </Space>

          <Button
            type="primary"
            onClick={handleAssignCredit}
            disabled={!selectedUser || !amount}
            loading={loading}
            block
          >
            Assign Credit
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default AssignCredit;
