import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Auth from './pages/Auth/Auth';
import Home from './pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Venue from './pages/Base/Venue';
import CreditPrice from './pages/Base/CreditPrice';
import AssignCredit from './pages/Base/AssignCredit';
import UserManagement from './pages/Base/UserManagement';
import Project from './pages/Trainer/Project';
import MyPage from './pages/Base/MyPage';
import BookingCalendar from './pages/Trainer/BookingCalendar';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const islogin = localStorage.getItem('islogin');

  return (
    <Router>
      {islogin === 'true' && <Navbar />} {/* Only show navbar if logged in */}
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route 
            path="/" 
            element={islogin === 'true' ? <Navigate to="/home" /> : <Auth />} 
          />
          <Route path="/home" element={<ProtectedRoute islogin={islogin}><Home /></ProtectedRoute>}/>
          <Route path="/base/venue" element={<ProtectedRoute islogin={islogin}><Venue /></ProtectedRoute>}/>
          <Route path="/base/credit_price" element={<ProtectedRoute islogin={islogin}><CreditPrice /></ProtectedRoute>}/>
          <Route path="/base/give_credit" element={<ProtectedRoute islogin={islogin}><AssignCredit /></ProtectedRoute>}/>
          <Route path="/base/user_management" element={<ProtectedRoute islogin={islogin}><UserManagement /></ProtectedRoute>}/>
          <Route path="/base/my_page" element={<ProtectedRoute islogin={islogin}><MyPage /></ProtectedRoute>}/>
          <Route path="/trainer/project" element={<ProtectedRoute islogin={islogin}><Project /></ProtectedRoute>}/>
          <Route path="/trainer/booking_calendar" element={<ProtectedRoute islogin={islogin}><BookingCalendar /></ProtectedRoute>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
