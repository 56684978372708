import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
export const getVenues = async () => {
    const response = await axios.get(`${API_URL}/base/venues`);
    return response.data;
}
export const addVenue = async (venue) => {
    const response = await axios.post(`${API_URL}/base/venues`, venue);
    return response.data;
}
export const updateVenue = async (venue_id, venue) => {
    console.log(venue);
    const response = await axios.put(`${API_URL}/base/venues/${venue_id}`, venue);
    return response.data;
}
export const deleteVenue = async (venue_id) => {
    const response = await axios.delete(`${API_URL}/base/venues/${venue_id}`);
    return response.data;
}
export const getVenueById = async (venue_id) => {
    const response = await axios.get(`${API_URL}/base/venues/${venue_id}`);
    return response.data;
}   
