import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './../../styles/Navbar/Navbar.css';
import { toast } from 'react-toastify';

const Navbar = ({ onLogout }) => {
  const [isBaseMenuOpen, setIsBaseMenuOpen] = useState(false);
  const [isTrainerMenuOpen, setIsTrainerMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    toast.success('Logged out successfully');
    navigate('/');
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/home">Home</Link>
        </li>
        <li 
          className="dropdown" 
          onMouseEnter={() => setIsBaseMenuOpen(true)} 
          onMouseLeave={() => setIsBaseMenuOpen(false)}
        >
          <span>Base</span>
          {isBaseMenuOpen && (
            <ul className="dropdown-menu">
              <li>
                <Link to="/base/venue">Venue</Link>
              </li>
              <li>
                <Link to="/base/credit_price">Credit Price</Link>
              </li>
              <li>
                <Link to="/base/give_credit">Assign Credit</Link>
              </li>
              <li>
                <Link to="/base/user_management">User Management</Link>
              </li>
              <li>
                <Link to="/base/my_page">My Page</Link>
              </li>
            </ul>
          )}
        </li>
        <li 
          className="dropdown" 
          onMouseEnter={() => setIsTrainerMenuOpen(true)} 
          onMouseLeave={() => setIsTrainerMenuOpen(false)}
        >
          <span>Trainer</span>
          {isTrainerMenuOpen && (
            <ul className="dropdown-menu">
              <li>
                <Link to="/trainer/project">Project</Link>
              </li>
              <li>
                <Link to="/trainer/booking_calendar">Booking Calendar</Link>
              </li>
              {/* <li>
                <Link to="/base/give_credit">Assign Credit</Link>
              </li>
              <li>
                <Link to="/base/user_management">User Management</Link>
              </li>
              <li>
                <Link to="/base/my_page">My Page</Link>
              </li> */}
            </ul>
          )}
        </li>
        <li>
          <button onClick={handleLogout}>Logout</button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;