import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUsers = async () => {
    const response = await axios.get(`${API_URL}/base/management/users`, {
        headers: { Authorization: localStorage.getItem('token') },
    });
    return response.data[0];
}

export const assignCredit = async (userId, creditType, amount) => {
  try {
    const response = await axios.post(`${API_URL}/base/credits/assign`, {
      userId,
      creditType,
      amount
    }, {
      headers: { Authorization: localStorage.getItem('token') },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to assign credit');
  }
}; 