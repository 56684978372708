import {
  Calendar,
  Badge,
  Modal,
  message,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { getBookings, getProjects, getVenues } from "../../services/Trainer/BookingCalendar";
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const BookingCalendar = ({ currentUser }) => {
  const [bookings, setBookings] = useState([]);
  const [projects, setProjects] = useState([]);
  const [venues, setVenues] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [recurrence, setRecurrence] = useState("none");
  const [availableCredits, setAvailableCredits] = useState(0);
  const [selectedProjectCredits, setSelectedProjectCredits] = useState(0);
  const [calculatedCredits, setCalculatedCredits] = useState(0);
  const trainer_id = localStorage.getItem("userId");
  const [selectedVenue, setSelectedVenue] = useState(null);

  useEffect(() => {
    fetchBookings();
    fetchProjects();
    fetchTrainerCredits();
    fetchVenues();
  }, []);
  const fetchVenues = async () => {
    const data = await getVenues();
    console.log(data);
    setVenues(data);
  };
  const fetchBookings = async () => {
    try {
      const data = [
        {
          id: 1,
          venue_name: "Gym Room A",
          start_time: "2023-11-20T10:00:00",
          recurrence: "none",
          user_id: trainer_id,
          status: "approved",
        },
        {
          id: 2,
          venue_name: "Tennis Court",
          start_time: "2023-11-22T09:00:00",
          recurrence: "weekly",
          user_id: trainer_id,
          status: "pending",
        },
      ];
      setBookings(data);
    } catch (error) {
      message.error("Failed to load bookings");
    }
  };

  const fetchProjects = async () => {
    try {
      const data = await getProjects(trainer_id);
      console.log(data);
      setProjects(data);
    } catch (error) {
      message.error("Failed to load projects");
    }
  };

  const fetchTrainerCredits = async () => {
    try {
      const data = { credits: 50 };
      setAvailableCredits(data.credits || 0);
    } catch (error) {
      message.error("Failed to load credits");
    }
  };

  const handleProjectChange = (projectId) => {
    const selectedProject = projects.find((project) => project._id === projectId);
    if (selectedProject) {
      setSelectedProjectCredits(selectedProject.project_credit);
    } else {
      setSelectedProjectCredits(0);
    }
  };

  const calculateCredits = (startTime, endTime) => {
    if (!startTime || !endTime || !selectedVenue) return 0;

    const start = moment(startTime);
    const end = moment(endTime);
    let totalCredits = 0;

    // Convert venue peak times to moment objects for comparison
    const peakStart = moment(selectedVenue.peak_start_time, 'HH:mm:ss');
    const peakEnd = moment(selectedVenue.peak_end_time, 'HH:mm:ss');

    // Calculate credits hour by hour
    let currentHour = moment(start);
    while (currentHour.isBefore(end)) {
      const nextHour = moment(currentHour).add(1, 'hour');
      const hourEnd = moment.min(nextHour, end);
      
      // Check if current hour is during peak time
      const timeToCheck = moment(currentHour).format('HH:mm:ss');
      const isPeakHour = moment(timeToCheck, 'HH:mm:ss').isBetween(peakStart, peakEnd, null, '[]');
      
      // Calculate fraction of hour if it's a partial hour
      const hourFraction = hourEnd.diff(currentHour, 'minutes') / 60;
      
      // Add credits based on peak/off-peak rate
      if (isPeakHour) {
        totalCredits += hourFraction * selectedVenue.credits_per_peak;
      } else {
        totalCredits += hourFraction * selectedVenue.credits_per_unpeak;
      }
      
      currentHour = nextHour;
    }

    return Math.ceil(totalCredits);
  };

  const handleTimeRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const credits = calculateCredits(start, end);
      setCalculatedCredits(credits);
    } else {
      setCalculatedCredits(0);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { project_id, venue_id, timeRange, purpose, recurrence_day } = values;
      const [start_time, end_time] = timeRange.map((time) =>
        moment(time).format("YYYY-MM-DD HH:mm:ss")
      );

      if (calculatedCredits > availableCredits) {
        message.error("Not enough credits for this booking");
        return;
      }

      console.log("Booking request:", {
        user_id: trainer_id,
        project_id,
        venue_id,
        start_time,
        end_time,
        purpose,
        recurrence,
        recurrence_day: recurrence === "weekly" ? recurrence_day : null,
        credits_required: calculatedCredits,
      });

      message.success("Booking request submitted");
      setIsModalOpen(false);
      fetchBookings();
      fetchTrainerCredits();
    } catch (error) {
      message.error("Failed to create booking");
    }
  };

  const dateCellRender = (date) => {
    const dayBookings = bookings.filter((booking) => {
      const bookingDate = moment(booking.start_time);
      if (booking.recurrence === "daily") return true;
      if (booking.recurrence === "weekly") return bookingDate.day() === date.day();
      return bookingDate.isSame(date, "day");
    });

    return (
      <ul style={{ listStyle: "none", padding: 0, margin: 0, overflow: "hidden" }}>
        {dayBookings.map((booking) => (
          <li
            key={booking.id}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            <Badge
              status={booking.user_id === trainer_id ? "processing" : "default"}
              text={`${booking.venue_name} (${booking.status})`}
            />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (date, info) => {
    const isBooked = bookings.some((booking) =>
      moment(booking.start_time).isSame(date, "day")
    );

    const cellStyle = {
      backgroundColor: isBooked ? "#d9f7be" : "#ccc",
      padding: "8px",
      borderRadius: "4px",
      minHeight: "60px",
      overflow: "hidden", // Ensure no scrollbars
    };

    return (
      <div style={cellStyle}>
        {info.originNode}
      </div>
    );
  };

  const handleVenueChange = (venueId) => {
    const venue = venues.find((v) => v._id === venueId);
    setSelectedVenue(venue);
    // Recalculate credits if time range is already selected
    const timeRange = form.getFieldValue('timeRange');
    if (timeRange && timeRange.length === 2) {
      handleTimeRangeChange(timeRange);
    }
  };

  return (
    <>
      <Title level={3}>Trainer Booking Calendar</Title>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          Request Booking
        </Button>
        <span style={{ marginLeft: 16 }}>
          Available Credits: <strong>{availableCredits}</strong>
        </span>
      </div>
      <Calendar
        dateCellRender={dateCellRender}
        cellRender={cellRender}
      />

      <Modal
        title="Request Booking"
        visible={isModalOpen}
        onOk={form.submit}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="venue_id"
            label="Select Venue"
            rules={[{ required: true, message: "Please select a venue" }]}
          >
            <Select onChange={handleVenueChange}>
              {venues.map((venue) => (
                <Option key={venue._id} value={venue._id}>
                  {venue.venue_name} (Peak: {venue.credits_per_peak}, Off-peak: {venue.credits_per_unpeak})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="project_id"
            label="Select Project"
            rules={[{ required: true, message: "Please select a project" }]}
          >
            <Select onChange={handleProjectChange}>
              {projects.map((project) => (
                <Option key={project._id} value={project._id}>
                  {project.project_name} (Credits: {project.project_credit})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="timeRange" label="Select Time" rules={[{ required: true }]}>
            <RangePicker showTime onChange={handleTimeRangeChange} />
          </Form.Item>
          <div style={{ marginBottom: 16 }}>
            <Typography.Text>
              Credits Required: <strong>{calculatedCredits}</strong>
              {selectedVenue && (
                <span style={{ marginLeft: 8, fontSize: '12px', color: '#666' }}>
                  (Peak hours: {selectedVenue.peak_start_time} - {selectedVenue.peak_end_time}, 
                  Peak rate: {selectedVenue.credits_per_peak}/hr, 
                  Off-peak rate: {selectedVenue.credits_per_unpeak}/hr)
                </span>
              )}
            </Typography.Text>
            {calculatedCredits > availableCredits && (
              <Typography.Text type="danger" style={{ marginLeft: 8 }}>
                (Exceeds available credits)
              </Typography.Text>
            )}
          </div>
          <Form.Item name="purpose" label="Purpose" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Recurrence">
            <Radio.Group onChange={(e) => setRecurrence(e.target.value)} value={recurrence}>
              <Radio value="none">Only Today</Radio>
              <Radio value="daily">Daily</Radio>
              <Radio value="weekly">Weekly</Radio>
            </Radio.Group>
          </Form.Item>
          {recurrence === "weekly" && (
            <Form.Item
              name="recurrence_day"
              label="Select Day of Week"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value="Monday">Monday</Option>
                <Option value="Tuesday">Tuesday</Option>
                <Option value="Wednesday">Wednesday</Option>
                <Option value="Thursday">Thursday</Option>
                <Option value="Friday">Friday</Option>
                <Option value="Saturday">Saturday</Option>
                <Option value="Sunday">Sunday</Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default BookingCalendar;
