import React, { useState, useEffect } from "react";
import { Form, Input, Button, Upload, notification, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getProfile, updateProfile } from "./../../services/Base/MyPage";

const MyPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [profileData, setProfileData] = useState({
    id: null,
    userId: localStorage.getItem("userId") || "", // Initialize from localStorage
    countryCode: "",
    mobileNo: "",
    profileText: "",
  });

  const fetchProfileData = async () => {
    try {
      const response = await getProfile();
      const profile = response.data[0];
      console.log(profile);
      setProfileData({
        id: profile._id || null,
        userId: localStorage.getItem("userId") || "", // Ensure userId is set
        countryCode: profile.country_code || "",
        mobileNo: profile.mobile_no || "",
        profileText: profile.profile || "",
      });
      setProfileImage(profile.image_file || null);

      form.setFieldsValue({
        user_id: profile.userId || localStorage.getItem("userId") || "",
        country_code: profile.country_code,
        mobile_no: profile.mobile_no,
        profile: profile.profile,
      });

      if (profile.image_file) {
        setFileList([
          {
            uid: "-1",
            name: "Profile Image",
            status: "done",
            url: `/uploads/${profile.image_file}`,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      notification.error({ message: "Failed to load profile" });
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleFormSubmit = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("user_id", profileData.userId); // Add userId to FormData
      formData.append("country_code", profileData.countryCode);
      formData.append("mobile_no", profileData.mobileNo);
      formData.append("profile", profileData.profileText);

      if (fileList.length > 0 && fileList[0]?.originFileObj) {
        formData.append("image_file", fileList[0].originFileObj);
      }

      console.log("Submitting FormData:", Array.from(formData.entries()));

      await updateProfile(profileData.id, formData);

      notification.success({ message: "Profile saved successfully!" });
      await fetchProfileData();
    } catch (error) {
      console.error("Error saving profile:", error);
      notification.error({ message: "Failed to save profile" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 600, margin: "0 auto", padding: "20px" }}>
      <h2>User Profile</h2>
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <Image
            width={200}
            src={
              profileImage ? `/uploads/${profileImage}` : "https://via.placeholder.com/200"
            }
            alt="Profile Image"
            style={{ marginBottom: "20px" }}
          />
        </div>
        {/* User ID field */}
        <Form.Item
          name="user_id"
          label="User ID"
          rules={[{ required: true, message: "User ID is required!" }]}
        >
          <Input
            disabled // Make the field read-only if necessary
            placeholder="User ID"
            value={profileData.userId}
          />
        </Form.Item>
        <Form.Item
          name="country_code"
          label="Country Code"
          rules={[{ required: true, message: "Please enter your country code!" }]}
        >
          <Input
            placeholder="+1"
            value={profileData.countryCode}
            onChange={(e) =>
              setProfileData({ ...profileData, countryCode: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="mobile_no"
          label="Mobile Number"
          rules={[{ required: true, message: "Please enter your mobile number!" }]}
        >
          <Input
            placeholder="123-456-7890"
            value={profileData.mobileNo}
            onChange={(e) =>
              setProfileData({ ...profileData, mobileNo: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="profile"
          label="Profile"
          rules={[{ required: true, message: "Please provide a brief profile!" }]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Tell us about yourself"
            value={profileData.profileText}
            onChange={(e) =>
              setProfileData({ ...profileData, profileText: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Profile Image">
          <Upload
            listType="picture"
            fileList={fileList}
            onChange={({ fileList }) => {
              setFileList(fileList);
              if (fileList[0]?.originFileObj) {
                setProfileImage(null); // Clear existing profile image when new file is selected
              }
            }}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Save Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MyPage;
