import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import { getPrices, updatePrice } from './../../services/Base/CreditPrice'; // API calls
import './../../styles/Base/CreditPrice.css';

const CreditPrice = () => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Fetch prices on component load
  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    setLoading(true);
    try {
      const prices = await getPrices();
      setData(prices.map((item, index) => ({ ...item, key: index + 1 }))); // Ensure each item has a `key`
    } catch (error) {
      console.error("Error fetching prices:", error);
      message.error('Failed to load prices. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Open modal for editing
  const openEditModal = (record) => {
    console.log(record);
    setEditingRecord(record);
    form.setFieldsValue({ price: record.price.replace('$', '') }); // Populate modal form
    setIsModalVisible(true);
  };

  // Handle save button in modal
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPrice = values.price;

      // Call the API to update the price
      await updatePrice(editingRecord._id, updatedPrice );
      // Update the table data locally
      // const updatedData = data.map(item =>
      //   item.credit_id === editingRecord.credit_id
      //     ? { ...item, price: updatedPrice }
      //     : item
      // );

      // setData(updatedData);
      fetchPrices();
      message.success('Price updated successfully!');
      setIsModalVisible(false);
    } catch (error) {
      console.error("Failed to update price:", error);
      message.error('Failed to update price. Please try again later.');
    }
  };

  return (
    <div>
      <h2>Credit Price Table</h2>
      <Table
        className="credit-price-table"
        columns={[
          {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
          },
          {
            title: 'Type',
            dataIndex: 'credit_type',
            key: 'credit_type',
            align: 'center',
          },
          {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            align: 'center',
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
          },
          {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
              <Button type="link" onClick={() => openEditModal(record)}>Edit</Button>
            ),
          },
        ]}
        dataSource={data}
        rowKey="credit_id" // Ensure unique key from API
        loading={loading}
        pagination={false}
      />

      <Modal
        title="Edit Price"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: 'Please enter the price' }]}
          >
            <Input prefix="$" type="number" min={0} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreditPrice;
