import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL   ;
export const getProjects = async (userId) => {
    const response = await axios.get(`${API_URL}/trainer/project`,{params: { userId }});
    // console.log(response.data);
    return response.data[0];
};  
export const createProject = async (project) => {
    const response = await axios.post(`${API_URL}/trainer/project`, project);
    return response.data;
};  
export const updateProject = async (id, project) => {
    const response = await axios.put(`${API_URL}/trainer/project/${id}`, project);
    return response.data;
};  
export const deleteProject = async (id) => {
    const response = await axios.delete(`${API_URL}/trainer/project/${id}`);
    return response.data;
};  
