import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export const fetchBookings = async () => {
  const response = await axios.get(`${API_URL}/bookings/`);
  return response.data;
};
export const getProjects = async (trainer_id) => {
  const response = await axios.get(`${API_URL}/trainer/booking/getprojects`, {params: { trainer_id }});
  return response.data;
};
export const getBookings = async (trainer_id) => {
  const response = await axios.get(`${API_URL}/trainer/booking/getbookings`, {params: { trainer_id }});
  return response.data;
};

export const getVenues = async () => {
  const response = await axios.get(`${API_URL}/trainer/booking/getvenues`);
  return response.data;
};
