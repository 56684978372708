import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, TimePicker, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { addVenue, updateVenue, deleteVenue, getVenues } from '../../services/Base/venue';
import moment from 'moment';
import './../../styles/Base/Venue.css';

const Venue = () => {
  const [venues, setVenues] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentVenue, setCurrentVenue] = useState(null);

  const [form] = Form.useForm();

  // Load venues when the component mounts
  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const data = await getVenues();
        setVenues(data);
      } catch (error) {
        message.error('Failed to load venues');
        console.error(error);
      }
    };

    fetchVenues();
  }, []);

  // Columns for the table
  const columns = [
    {
      title: 'No',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Venue Name',
      dataIndex: 'venue_name',
      key: 'venue_name',
    },
    {
      title: 'Peak Start',
      dataIndex: 'peak_start',
      key: 'peak_start',
    },
    {
      title: 'Peak End',
      dataIndex: 'peak_end',
      key: 'peak_end',
    },
    {
      title: 'Credit per Peak',
      dataIndex: 'credit_per_peak',
      key: 'credit_per_peak',
    },
    {
      title: 'Credit per Unpeak',
      dataIndex: 'credit_per_unpeak',
      key: 'credit_per_unpeak',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            className='edit-venue-button'
            type="link"
            onClick={() => openEditModal(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            className='delete-venue-button'
            type="link"
            danger
            onClick={() => handleDeleteVenue(record._id)}
          >
            <DeleteOutlined />
          </Button>
        </>
      ),
    },
  ];

  // Open the modal to add a new venue
  const openAddModal = () => {
    form.resetFields();
    setIsEditing(false);
    setCurrentVenue(null);
    setIsModalVisible(true);
  };

  // Open the modal to edit an existing venue
  const openEditModal = (venue) => {
    setIsEditing(true);
    setCurrentVenue(venue);
    console.log(venue)
    form.setFieldsValue({
      venue_name: venue.venue_name,
      peak_start: moment(venue.peak_start, 'HH:mm'),
      peak_end: moment(venue.peak_end, 'HH:mm'),
      credit_per_peak: venue.credit_per_peak,
      credit_per_unpeak: venue.credit_per_unpeak,
    });
    setIsModalVisible(true);
  };

  // Handle form submission for add/edit
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const newVenue = {
        venue_name: values.venue_name,
        peak_start: values.peak_start.format('HH:mm'),
        peak_end: values.peak_end.format('HH:mm'),
        credit_per_peak: values.credit_per_peak,
        credit_per_unpeak: values.credit_per_unpeak,
      };
      console.log(newVenue)
      if (isEditing && currentVenue) {
        // Update existing venue
        console.log(newVenue);
        await updateVenue(currentVenue._id, newVenue);
        setVenues((prev) =>
          prev.map((venue) =>
            venue._id === currentVenue._id ? { ...venue, ...newVenue } : venue
          )
        );
        message.success('Venue updated successfully!');
      } else {
        // Add new venue
        const addedVenue = await addVenue(newVenue);
        setVenues((prev) => [...prev, { ...newVenue, key: addedVenue.key }]);
        message.success('Venue added successfully!');
      }

      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to save venue');
      console.error('Validation failed:', error);
    }
  };

  // Handle deletion of a venue
  const handleDeleteVenue = async (key) => {
    console.log(key);
    try {
      await deleteVenue(key);
      setVenues((prev) => prev.filter((venue) => venue.key !== key));
      message.success('Venue deleted successfully!');
    } catch (error) {
      message.error('Failed to delete venue');
      console.error(error);
    }
  };

  return (
    <div className='container'>
      <h1><center>Venue</center></h1>
      <div>
        <Button type="primary" onClick={openAddModal} className='add-venue-button'>
          Add Venue
        </Button>

        <Table className='venue-table' columns={columns} dataSource={venues} rowKey="key" />

        <Modal
          title={isEditing ? 'Edit Venue' : 'Add Venue'}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={handleOk}
          okText={isEditing ? 'Update' : 'Add'}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="venue_name"
              label="Venue Name"
              rules={[{ required: true, message: 'Please enter the venue name' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="peak_start"
              label="Peak Start"
              rules={[{ required: true, message: 'Please select peak start time' }]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>

            <Form.Item
              name="peak_end"
              label="Peak End"
              rules={[{ required: true, message: 'Please select peak end time' }]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>

            <Form.Item
              name="credit_per_peak"
              label="Credit per Peak"
              rules={[{ required: true, message: 'Please enter the credit per peak' }]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              name="credit_per_unpeak"
              label="Credit per Unpeak"
              rules={[{ required: true, message: 'Please enter the credit per unpeak' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Venue;
