import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Space, Typography, Row, Col, Modal, Form, Input, InputNumber, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './../../styles/Trainer/Project.css';
import { getProjects, createProject, updateProject, deleteProject } from './../../services/Trainer/Project';

const { Title } = Typography;

const Project = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingProject, setEditingProject] = useState(null); // State to store the project being edited
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  // Fetch projects on component mount
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await getProjects(localStorage.getItem("userId"));

      // Check if the response contains an array or a single object
      if (Array.isArray(response)) {
        setDataSource(response.map((item) => ({ ...item, key: item._id })));
      } else if (response && typeof response === "object") {
        setDataSource([{ ...response, key: response._id }]); // Wrap single object in an array
      } else {
        throw new Error("Invalid response format from backend");
      }
    } catch (error) {
    }
  };

  const handleEdit = (project) => {
    setEditingProject(project); // Store the project being edited
    form.setFieldsValue({
      project_name: project.project_name,
      description: project.project_description,
      credit: project.project_credit,
    }); // Pre-fill the form with project data
    setIsModalVisible(true);
  };

  const handleDelete = async (projectId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await deleteProject(projectId); // Call API to delete the project
        message.success('Project deleted successfully');
        setDataSource(dataSource.filter((project) => project._id !== projectId)); // Update the table
      } catch (error) {
        message.error('Failed to delete project');
      }
    }
  };

  const handleSubmit = async (values) => {
    const projectData = {
      trainer_id: localStorage.getItem("userId"),
      project_name: values.project_name,
      project_description: values.description,
      project_credit: values.credit,
    };

    if (editingProject) {
      // Update existing project
      await updateProject(editingProject._id, projectData); // Call API to update project
      fetchProjects(); // Reload projects from backend
      message.success('Project updated successfully');
    } else {
      // Create new project
      const response = await createProject(projectData);
      setDataSource([...dataSource, { ...projectData, _id: response._id, key: response._id }]);
      message.success('Project created successfully');
    }

    setIsModalVisible(false);
    setEditingProject(null);
    form.resetFields();
  };

  // Table columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: 'Description',
      dataIndex: 'project_description',
      key: 'project_description',
    },
    {
      title: 'Credit',
      dataIndex: 'project_credit',
      key: 'project_credit',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            type="link"
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            type="link"
            danger
            onClick={() => handleDelete(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      {/* Statistics Section */}
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card>
            <Title level={5}>Total Projects</Title>
            <Title level={4}>{dataSource.length}</Title>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Title level={5}>Active Trainees</Title>
            <Title level={4}>10</Title>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Title level={5}>Revenue</Title>
            <Title level={4}>${dataSource.reduce((total, project) => total + (project.project_credit || 0), 0)}</Title>
          </Card>
        </Col>
      </Row>

      {/* Create New Project Button */}
      <div className="create-button">
        <Button
          type="primary"
          onClick={() => {
            setEditingProject(null); // Clear editing state
            form.resetFields(); // Reset the form
            setIsModalVisible(true); // Show modal
          }}
        >
          + Create New Project
        </Button>
      </div>

      {/* Projects Table */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 5 }}
        style={{ marginTop: '16px' }}
      />

      {/* Modal for Creating/Editing Project */}
      <Modal
        title={editingProject ? 'Edit Project' : 'Create New Project'}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingProject(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="project_name"
            label="Project Name"
            rules={[{ required: true, message: 'Please enter the project name!' }]}
          >
            <Input placeholder="Enter project name" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description!' }]}
          >
            <Input placeholder="Enter description" />
          </Form.Item>

          <Form.Item
            name="credit"
            label="Credit"
            rules={[{ required: true, message: 'Please enter the credit amount!' }]}
          >
            <InputNumber
              placeholder="Enter credit amount"
              style={{ width: '100%' }}
              min={1}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {editingProject ? 'Update Project' : 'Create Project'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Project;
