import React, { useState } from 'react';
import './../../styles/Auth/Auth.css';
import { login, register } from './../../services/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Auth = () => {
    const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (!isLogin && formData.user_password !== formData.confirmPassword) {
        toast.error("Passwords don't match!");
        setLoading(false);
        return;
      }

      let response;
      if (isLogin) {
        response = await login(formData.user_email, formData.user_password);
        
        if (response.message === "Login successful") {
          localStorage.setItem('token', response.token);
          localStorage.setItem('islogin', 'true');
          localStorage.setItem('user', JSON.stringify(response.user));
          toast.success('Login successful!');
          window.location.href = `${window.location.origin}/home`;
        } else {
          throw new Error(response.error || 'Login failed');
        }
      } else {
        // Handle registration
        response = await register(
          formData.user_name,
          formData.user_email,
          formData.user_password
        );
        
        if (response.success || response.message) {
          toast.success('Registration successful! Please login.');
          setIsLogin(true);
          setFormData({
            user_name: '',
            user_email: '',
            user_password: '',
            confirmPassword: ''
          });
        } else {
          throw new Error(response.error || 'Registration failed');
        }
      }
      
    } catch (err) {
      console.error('Auth error:', err);
      toast.error(err.message || 'An error occurred. Please try again.');
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>{isLogin ? 'Login' : 'Register'}</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          {!isLogin && (
            <div className="form-group">
              <input
                type="text"
                name="user_name"
                placeholder="User Name"
                value={formData.user_name}
                onChange={handleChange}
                required={!isLogin}
              />
            </div>
          )}
          <div className="form-group">
            <input
              type="email"
              name="user_email"
              placeholder="Email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="user_password"
              placeholder="Password"
              value={formData.user_password}
              onChange={handleChange}
              required
            />
          </div>
          {!isLogin && (
            <div className="form-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required={!isLogin}
              />
            </div>
          )}
          <button type="submit" disabled={loading}>
            {loading ? 'Loading...' : (isLogin ? 'Login' : 'Register')}
          </button>
        </form>
        <p>
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <span
            className="toggle-auth"
            onClick={() => {
              setIsLogin(!isLogin);
              setError('');
              setFormData({
                user_name: '',
                user_email: '',
                user_password: '',
                confirmPassword: ''
              });
            }}
          >
            {isLogin ? 'Register' : 'Login'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Auth;
