import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
export const getProfile = async () => {
    const response = await axios.get(`${API_URL}/base/userprofile/${localStorage.getItem('userId')}`, {
        headers: { Authorization: localStorage.getItem('token') },
    });
    return response;
}
export const updateProfile = async (id, data) => {
    const response = await axios.put(`${API_URL}/base/userprofile/${id}`, data, {
        headers: { Authorization: localStorage.getItem('token') },
    });
    return response;
}
