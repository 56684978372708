import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
export const getPrices = async () => {
    const response = await axios.get(`${API_URL}/base/creditprices`);
    return response.data;
}
export const updatePrice = async (credit_id, price) => {
    const response = await axios.put(`${API_URL}/base/creditprices/${credit_id}`, {'price': price});
    return response.data;
}
